import { postRequest, getRequest } from './index'
import { APISRCUL } from '@/config'
// 查询店铺分页列表
export const queryShopListPage = (data, successCallback, failureCallback) => {
  postRequest(`${APISRCUL}/shop/queryShopListPage`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查询店铺配置专区信息详情
export const queryShopConfigInfoDesc = (id, successCallback, failureCallback) => {
  getRequest(`${APISRCUL}/shop/queryShopConfigInfoDesc/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查询店铺基本信息详情
export const queryShopBaseInfoDesc = (id, successCallback, failureCallback) => {
  getRequest(`${APISRCUL}/shop/queryShopBaseInfoDesc/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 查询产品（搜本店）
export const pageSpotHallGoodsShop = (data, successCallback, failureCallback) => {
  postRequest(`${APISRCUL}/shop/pageSpotHallGoodsShop`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
